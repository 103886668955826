<template>
  <div class="page1">
    <Row class="listTop">
      <Col span="7">
        <div class="leftTop">
          <span class='title'><span class="title-text">机构简介</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describe">长沙市雨花区群英公益发展促进中心（简称：群英会）创建于2008年，2013年7月正式注册成为一家“枢纽型”社会公益组织。群英会以公益为纽带，以“资源共享、资讯互通、合作共赢、服务民生”为开办宗旨，既尽力满足了社会人士对各类社会资讯的需求，也较好地引导了各个企事业单位和社会组织(团体)积极参与公益履行社会责任，为推动社会治理创新与和谐发展贡献智慧和力量。群英会目前是中国乡村发展基金会、壹基金等国内知名公益机构湖南区域总协调机构，群英会发起的“为湖南而教”、“乡村园长希望+”、“守护湘间的花蕾”、“温暖湘里娃”、“五美好少年”等公益项目，获得社会高度认可与公众广泛参与。</div>
        </div>
        <div class="leftCenter">
          <span class='title'><span class="title-text">湖湘枢纽型公益平台简介</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describe">
            <span>平台聚焦湖南“三高四新”战略定位和使命任务，大力实施“强省会”战略，致力于搭建政府、企业、公众、社会组织共同参与社会服务的湖湘枢纽型公益平台。通过能力建设、项目合作、资源对接、专业研究、社会企业等模式促进湖湘公益行业健康发展，推动湖南社会治理的实践和创新。</span>
          </div>
        </div>
        <div class="leftBottom">
          <span class="title"><span class="title-text">政社联动</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describe2">
            湖湘公益统一战线枢纽型公益平台<br/>
            湖南省志愿服务联合会公益中心<br/>
            残疾人防返贫监测服务<br/>
            湖南省社会组织能力建设支持平台<br/>
            湖南省禁毒科普教育<br/>
            长沙市雨花区生态保护联合会<br/>
            长沙县小动物保护协会
          </div>
        </div>
      </Col>
      <Col span="10" class="chinaMap">
        <!--                <radar-part ref="chart6"></radar-part>-->
        <china-map ref="chinaMap"></china-map>
      </Col>
      <Col span="7">
        <div class="right right-1">
          <span class="title"><span class="title-text">公益服务数据</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describe2">
            <div class="item">公益项目：<span class="countData">{{ publicList.work | getThousand }}个</span></div>
            <div class="item">服务人次：<span class="countData">{{ publicList.people | getThousand }}人次</span></div>
            <div class="item">志愿者人数：<span class="countData">{{ publicList.count | getThousand }}人</span></div>
            <div class="item">款物投入：<span class="countData">{{ publicList.total | getThousand }}万元</span></div>
            <div class="item">社会组织参与数：<span class="countData">{{ publicList.social | getThousand }}个</span></div>
          </div>
        </div>
        <div class="right right-2">
          <span class="title"><span class="title-text">各界关怀</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <pictures></pictures>
        </div>
        <div class="right right-3">
          <span class="title"><span class="title-text">社会认可</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <cooperation-logo></cooperation-logo>
        </div>
        <div class="right right-4">
          <span class='title'><span class="title-text">共建单位</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="unit_choose">
            <div v-for="(item,index) in cooperList" :key="index" :class="index==num?'on_unit':'unit'" @click="getUnitFn(item,index)">{{item.title}}</div>
            <!--                    <div :class="num==2?'on_unit':'unit'" @click="twoUnit">企业</div>-->
            <!--                    <div :class="num==3?'on_unit':'unit'" @click="threeUnit">政府</div>-->
          </div>
          <!--                    <cooperation-logo :idx="0"></cooperation-logo>-->
          <!--                    <div class="rightTop-list">-->
          <!--                        <div class="list">-->
          <!--                            <popular-bar ref="chart8"></popular-bar>-->
          <!--                        </div>-->
          <!--                        <div class="list">-->
          <!--                            <ring ref="chart9" :data="data5"></ring>-->
          <!--                        </div>-->
          <!--                        <div class="list">-->
          <!--                            <ring ref="chart10" :data="data6"></ring>-->
          <!--                        </div>-->
          <!--                    </div>-->
        </div>
      </Col>
    </Row>
    <cooperFather ref="cooper"></cooperFather>
  </div>
</template>

<script>
import {formatNum} from '@/lib/toThousands'
const  cooperationLogo =()=> import("@/views/components/workPicture/cooperationLogo");
const chinaMap  = () => import('./components/indexMap');
const pictures =()=> import('./components/workPicture/picture');
const cooperFather =()=> import('./components/page1/cooperation_father')
// const webBar = () => import('./components/page1/webBar.vue');
// const pie = () => import('./components/pie')
// const ring = () => import('./components/page1/ring');
// const popularBar = () => import('./components/page1/popularBar');
// const lines = () => import('./components/page1/lines');
// const pieRing = () => import('./components/page1/pieRing');
// const colorsBar = () => import('./components/page6/colorsBar');
// const lineBar = () => import('./components/page1/lineBar');
export default {
  name: 'page1',
  props: {
    selectRangeDate: Array
  },
  components: {
    cooperationLogo,
    cooperFather,
    // radarPart,// 中心
    chinaMap, //湖南省地图
    pictures,
    // doubleArea, // 面积图
    // webBar, // 柱图
    // pie, // 饼图
    // worldMap, // 世界地图
    // ring, // 圆环
    // popularBar, // 柱图
    // lines, //折线图
    // pieRing, // 饼环图
    // colorsBar,// 柱图
    // funnel, // 漏斗图
    // lineBar, //柱图
    // areaChart // 面积图
  },
  created() {
  },
  data() {
    return {
      num:-1,
      chooseIndex:-1,
      publicList:{
        work:28,
        people:1437203,
        count:29163,
        total:97483.9101,
        social:315
      },
      indexData:{

      },
      // list:[],
      cooperList:[
        {
          title:'政府',
          list:[
            require('@/assets/index-image/unitLogo/three/1.jpg'),
            require('@/assets/index-image/unitLogo/three/2.jpg'),
            require('@/assets/index-image/unitLogo/three/3.jpg'),
            require('@/assets/index-image/unitLogo/three/4.jpg'),
            require('@/assets/index-image/unitLogo/three/5.jpg'),
            require('@/assets/index-image/unitLogo/three/6.jpg'),
            require('@/assets/index-image/unitLogo/three/7.jpg'),
            require('@/assets/index-image/unitLogo/three/8.jpg'),
            require('@/assets/index-image/unitLogo/three/9.jpg'),
          ]
        },
        {
          title:'企业',
          list:[
            require('@/assets/index-image/unitLogo/two/1.jpg'),
            require('@/assets/index-image/unitLogo/two/2.jpg'),
            require('@/assets/index-image/unitLogo/two/3.jpg'),
            require('@/assets/index-image/unitLogo/two/4.jpg'),
            require('@/assets/index-image/unitLogo/two/5.jpg'),
            require('@/assets/index-image/unitLogo/two/6.jpg'),
            require('@/assets/index-image/unitLogo/two/7.jpg'),
            require('@/assets/index-image/unitLogo/two/8.jpg'),
            require('@/assets/index-image/unitLogo/two/9.jpg'),
            require('@/assets/index-image/unitLogo/two/10.jpg'),
            require('@/assets/index-image/unitLogo/two/11.jpg'),
            require('@/assets/index-image/unitLogo/two/12.jpg'),
            require('@/assets/index-image/unitLogo/two/13.jpg'),
          ]
        },
        {
          title:'基金会',
          list:[
            require('@/assets/index-image/unitLogo/one/1.jpg'),
            require('@/assets/index-image/unitLogo/one/2.jpg'),
            require('@/assets/index-image/unitLogo/one/3.jpg'),
            require('@/assets/index-image/unitLogo/one/4.jpg'),
            require('@/assets/index-image/unitLogo/one/5.jpg'),
            require('@/assets/index-image/unitLogo/one/6.jpg'),
            require('@/assets/index-image/unitLogo/one/7.jpg'),
            require('@/assets/index-image/unitLogo/one/8.jpg'),
            require('@/assets/index-image/unitLogo/one/9.jpg'),
            require('@/assets/index-image/unitLogo/one/10.jpg'),
            require('@/assets/index-image/unitLogo/one/11.jpg'),
            require('@/assets/index-image/unitLogo/one/12.jpg'),
            require('@/assets/index-image/unitLogo/one/13.jpg'),
          ]
        },
      ],
      data1: { // 柱图数据1
        name: '柱图数据1',
        number: '100次',
        data: ["排行1", "排行2", "排行3", "排行4", "排行5"],
        color: '192,35,42',
        value: [60, 50, 40, 30, 20]
      },
      data2: {// 饼图数据1
        title: "饼图数据1分类占比",
        color: '#BE232A',
        data: [
          {
            value: 60,
            name: '分类1',
            itemStyle: {
              color: '#a262f2'
            }
          },
          {
            value: 20,
            name: '分类2',
            itemStyle: {
              color: '#2ca8fe'
            }
          },
          {
            value: 80,
            name: '分类3',
            itemStyle: {
              color: '#feac2c'
            }
          },
          {
            value: 40,
            name: '分类4',
            itemStyle: {
              color: '#c0232a'
            }
          },
          {
            value: 40,
            name: '分类5',
            itemStyle: {
              color: '#2cd9fe'
            }
          },
          {
            value: 40,
            name: '分类6',
            itemStyle: {
              color: '#ff787e'
            }
          },
          {
            value: 30,
            name: '其他',
            itemStyle: {
              color: '#252448'
            }
          }
        ],
      },
      data3: { // 柱图数据2
        name: '柱图数据2',
        number: '100次',
        data: ["排行1", "排行2", "排行3", "排行4", "排行5"],
        color: '40,112,232',
        value: [6, 5, 4, 3, 2]
      },
      // 饼图数据2
      data4: {
        title: "饼图数据2分类占比",
        color: '#2C7BFE',
        data: [
          {
            value: 20,
            name: '分类1',
            itemStyle: {
              color: '#feed2c'
            }

          },
          {
            value: 20,
            name: '分类2',
            itemStyle: {
              color: '#2ca8fe'
            }
          },
          {
            value: 40,
            name: '分类3',
            itemStyle: {
              color: '#feac2c'
            }
          },
          {
            value: 40,
            name: '分类4',
            itemStyle: {
              color: '#2c7bfe'
            }
          },
          {
            value: 100,
            name: '其他',
            itemStyle: {
              color: '#252448'
            }
          }
        ],
      },
      // 环形图数据
      data5: {
        title: '环形图数据1',
        data: [
          {
            value: 335,
            name: '模拟1',
            itemStyle: {
              color: '#252448'
            }
          },
          {
            value: 310,
            name: '模拟2',
            itemStyle: {
              color: '#2ca8fe'
            }
          },
          {
            value: 234,
            name: '模拟3',
            itemStyle: {
              color: '#feed2c'
            }
          },
          {
            value: 135,
            name: '其他',
            itemStyle: {
              color: '#2871ea'
            }
          },
          {
            value: 200,
            name: '模拟4',
            itemStyle: {
              color: '#fe672c'
            }
          }
        ]
      },
      // 环形数据2
      data6: {
        title: '热词传播次数',
        data: [
          {
            value: 335,
            name: '模拟1',
            itemStyle: {
              color: '#69f262'
            }
          },
          {
            value: 310,
            name: '模拟2',
            itemStyle: {
              color: '#c0232a'
            }
          },
          {
            value: 234,
            name: '模拟3',
            itemStyle: {
              color: '#2cfcfe'
            }
          },
          {
            value: 135,
            name: '其他',
            itemStyle: {
              color: '#252448'
            }
          },
          {
            value: 200,
            name: '模拟4',
            itemStyle: {
              color: '#a262f2'
            }
          }
        ]
      },
      data7: {
        title: '收支笔数及占比',
        data: [
          {
            value: 70,
            name: '收入',
            itemStyle: {
              color: '#c0232a'
            }
          },
          {
            value: 60,
            name: '支出',
            itemStyle: {
              color: '#2870e8'
            }
          },
        ],
        data1: [
          {
            value: 40,
            name: '红包',
            itemStyle: {
              color: '#c0232a'
            }
          },
          {
            value: 60,
            name: '转账',
            itemStyle: {
              color: '#2870e8'
            }
          },
        ]
      },
      data8: {
        title: '收支金额及占比',
        data: [
          {
            value: 80,
            name: '收入',
            itemStyle: {
              color: '#c0232a'
            }
          },
          {
            value: 60,
            name: '支出',
            itemStyle: {
              color: '#2870e8'
            }
          },
        ],
        data1: [
          {
            value: 40,
            name: '红包',
            itemStyle: {
              color: '#c2232a'
            }
          },
          {
            value: 60,
            name: '购物',
            itemStyle: {
              color: '#fe672c'
            }
          },
          {
            value: 40,
            name: '旅游',
            itemStyle: {
              color: '#a262f2'
            }
          },
          {
            value: 20,
            name: '其他',
            itemStyle: {
              color: '#2870e8'
            }
          },
          {
            value: 80,
            name: '交通',
            itemStyle: {
              color: '#feed2c'
            }
          }
        ]
      },
      // 交友分析面积图
      configData9: {
        title: '【交友分析】',
        color: '#75deef',
        name: ['（人）', '（人）'],
        data: [
          {
            name: '新增好友数',
            color: ['#feed2c', '#353103'],
            data: [240, 132, 101, 134, 90, 170, 110]
          },
          {
            name: '好友总数',
            color: ['#2871ea', '#0a1b41'],
            data: [20, 102, 101, 134, 190, 150, 120]
          },
          {
            name: '新增群数',
            color: ['#935adf', '#230f3e'],
            data: [100, 32, 101, 134, 150, 110, 180]
          },
          {
            name: '群总数',
            color: ['#e65f2d', '#551f0b'],
            data: [120, 122, 141, 144, 60, 220, 120]
          }
        ]
      },
      // 交友方式柱图
      colorsData: [
        {
          itemStyle: {
            color: "#2c7bfe",

          },
          name: '漂流瓶',
          value: 255
        },
        {
          itemStyle: {
            color: "#c2232a",

          },
          name: '附近的人',
          value: 212
        },
        {
          itemStyle: {
            color: "#feed2c",

          },
          name: '雷达',
          value: 155
        },
        {
          itemStyle: {
            color: "#a262f2",

          },
          name: '摇一摇',
          value: 55
        },
        {
          itemStyle: {
            color: "#62d5f2",
          },
          name: '搜索',
          value: 80
        },
        {
          itemStyle: {
            color: "#fe672c",
          },
          name: '群聊',
          value: 160
        },
        {
          itemStyle: {
            color: "#69f262",
          },
          name: '扫一扫',
          value: 114
        },
        {
          itemStyle: {
            color: "#2ca8fe",
          },
          name: '其他',
          value: 20
        },
      ],
      resizeFn: null
    }
  },
  methods: {
    getUnitFn(item,idx){
      this.num = idx;
      this.$refs.cooper.title = item.title;
      this.$refs.cooper.List = this.cooperList[idx];
      this.$refs.cooper.dialogVisible = true;
    },
    },
  filters:{
    getThousand(num){
      return formatNum(JSON.stringify(num));
    }
  },
  watch: {
    selectRangeDate: function () {
      for (let i = 1; i < 18; i++) {
        this.$refs['chart' + i].setChart();
      }
    }
  },
  mounted() {
    this.resizeFn = this.$debounce(() => {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      for (let i = 1; i < 18; i++) {
        this.$refs['chart' + i].setChart();
      }
    }, 500)
    window.addEventListener('resize', this.resizeFn)

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }

}

</script>

<style lang="less">
.page1 {
  //margin-top: 20px;
  height: 98%;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 20px 20px;
  background: #03044A;
  overflow: hidden;

  .listTop {
    height: 100%;

    .ivu-col {
      height: 100%;

      .leftTop {
        height: 42%;

      }
      .leftCenter{
        height: 22%;
        margin: 5% 0;
      }
      .right-1{
        height: 21%;
      }
      .right-4{
        height: 20%;
        .unit_choose{
          width: 80%;
          height: 20%;
          margin: 12% auto;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .unit{
            width: auto;
            min-width:28%;
            text-align: center;
            background-color: #2788b1;
            color: #fff;
            //height: 100%;
            //line-height: 25px;
            padding: 5px 10px;
            font-size: 16px;
            font-family: "华文细黑", Courier New, Courier, monospace;
            border-radius: 1px;
          }
          .on_unit{
            background-color: #fff;
            color: #2788b1;
            width: auto;
            min-width:28%;
            text-align: center;
            padding: 5px 10px;
            font-size: 16px;
            font-family: "华文细黑", Courier New, Courier, monospace;
            border-radius: 1px;
          }
        }
      }
      .right-2{
        height: 31%;
        margin: 3%;
      }
      .right-3{
        margin-bottom: 3%;
        height: 22%;
      }
      .right{
        margin-left: 20px;
        width: 96%!important;
      }
      .leftTop,.leftCenter,.leftBottom,.right{
        width:100%;
        border: 1px solid #0D2451;
        position: relative;
        box-sizing: border-box;
        background:rgb(8,25,79);
        .title {
          position: absolute;
          display: inline-block;
          color: #6EDDF1;
          border: 2px solid #6EDDF1;
          height: 18px;
          padding: 0 2px;
          left: 50%;
          transform: translate(-50%, -50%);
          .title-text {
            position: relative;
            font-size: 16px;
            background: #09102E;
            display: inline-block;
            padding: 0 4px;
            width: auto;
            transform: translateY(-5px);
            font-family:"宋体", "Times New Roman", Times, serif;;
          }
        }
        .describe2{
          flex-direction: column;
          line-height: 30px;
          padding-top:3%;
        }
        .describe{
          align-items: center;
          line-height: 35px;
          padding-top:2%;
        }
        .describe,.describe2{
          display: flex;
          margin:0 auto;
          font-size: 15px;
          color:rgb(167,198,235);
          width: 96%;
          height: 100%;
          font-family:"华文细黑",Courier New, Courier, monospace;
          //font-family:"华文仿宋",Georgia,"Times New Roman",Times,serif;
          .item{
            display: flex;
          }
          .countData{
            color:rgb(213,197,66);
          }
        }
      }
      .leftBottom{
        height: 29%;

        .ivu-row {
          &.topLine {
            height: 55%;
          }

          &.bottomPie {
            height: 45%;
          }

          .ivu-col {
            height: 100%;

            .charts-list {
              height: 100%;
              width: 100%;
            }
          }
        }

        .behavior {
          width: 100%;
          height: 100%;
        }

        .appUse {
          width: 100%;
          height: 100%;
        }
      }
      .right-top{
        margin-top: 0!important;
        margin-left: 20px;
        height: 50% !important;
      }
      .chinaMap{
        margin: 0 10px;
        height: 101%;
        background:rgb(8,25,79);
      }

      .rightTop-1 {
        width: 100%;
        height: 55%;
        border: 1px solid #0D2451;
        position: relative;
      }

      .rightTop-2 {
        width:96.5%;
        margin-top: 5%;
        height: 45.6%;
        margin-left: 20px;
        border: 1px solid #0D2451;
        position: relative;
        box-sizing: border-box;
        .rightTop-list {
          margin-top: 15px;
          width: 100%;
          height: 100%;

          .list {
            width: 30%;
            height: 100%;
            float: left;

            &:first-child {
              width: 40%;
            }
          }
        }
      }
      .right-bottom{
        margin-top: 4%;
        height: 30%;
        border: 1px solid #0D2451;
        position: relative;

        .ivu-row {
          &.topLine {
            height: 55%;
          }

          &.bottomPie {
            height: 45%;
          }

          .ivu-col {
            height: 100%;

            .charts-list {
              height: 100%;
              width: 100%;
            }
          }
        }

        .behavior {
          width: 100%;
          height: 100%;
        }

        .appUse {
          width: 100%;
          height: 100%;
        }
      }

    }
  }

  .listBottom {
    height: 40%;

    .ivu-col-span-9 {
      width: 33.5%;
      margin-right: 0.6667%;
    }

    .ivu-col-span-4 {
      width: 17.66667%;
      margin-right: 0.6667%;
    }
    .loudou{
      height: 500px!important;
    }

    .ivu-col {
      height: 100%;
    }
  }
}
</style>
