export function formatNum(str) {
    var newStr = "";
    var count = 0;
    // 当数字是整数
    if (str.indexOf(".") == -1) {
        for (var i = str.length - 1; i >= 0; i--) {
            if (count % 4 == 0 && count != 0) {
                newStr = str.charAt(i) + "," + newStr;
            } else {
                newStr = str.charAt(i) + newStr;
            }
            count++;
        }
        str = newStr; //自动补小数点后两位
        return str;
    }
    // 当数字带有小数
    else {
        for (var j = str.indexOf(".") - 1; j >= 0; j--) {
            if (count % 4 == 0 && count != 0) {
                newStr = str.charAt(j) + "," + newStr;
            } else {
                newStr = str.charAt(j) + newStr; //逐个字符相接起来
            }
            count++;
        }
        str = newStr + (str).substr((str + "00").indexOf("."));
        // str = newStr;
        return str;
    }
}